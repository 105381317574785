import { render, staticRenderFns } from "./ImmediatePast.vue?vue&type=template&id=29e20cb4&scoped=true"
import script from "./ImmediatePast.vue?vue&type=script&lang=js"
export * from "./ImmediatePast.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e20cb4",
  null
  
)

export default component.exports