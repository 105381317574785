<template>
  <div>
    <ImageComponent
      :clazz="'intro-image'"
      src="/assets/fatherland/37.jpg"
      alt="Elena Chemerska in her studio, 2019"
    >
      <template v-slot:headline>
        <h2>artist statement</h2>
      </template>
      <template v-slot:description>
        <p>
          Broadly said, my artistic practice is embedded in exploring the
          relationships between aesthetics, materiality, and politics. By
          employing different artistic strategies and approaches to
          image-making, my research focuses on the ways in which we create the
          basis for the future in relation to memory and the way we attempt to
          grasp history in its image. I approach human experience by framing it
          as a subject through the relationship of what Paolo Virno defines as
          "memory as the mark of the past pressed into our experience of the
          present", with what he defines as "hope as a horizon of possibility."
        </p>
        <p>
          I formulate my work through moving images, sound, writing, painting,
          drawing, and collaborations. Combining these elements, I tend to build
          elaborate installations which are intended to function like spatial
          and temporal landscapes; different speculative spaces that converge in
          the body experiencing the work. As a strategy, I often collaborate
          with allies from across the spectrum of disciplines with the aim to
          confront and defy conditions of precarity.
        </p>
        <p>
          In our dynamic world of rapid cultural and technological change, whose
          wounded body is rebelling against all the human mastering and its
          assumptions of progress, we have reached a point of uncertainty about
          whether human life on this planet as we know it can continue to exist.
          Our bodies are fighting against the velocity of data which rapidly
          dissolves categories we once deemed solid. At the same time, the human
          experience of being in the world is now visual and visualized to an
          unprecedented degree. We are becoming more aware of our deep
          involvement and constant exchange with the environment that we depend
          upon, as well as another human, non-human, and more-than-human bodies.
          Drawing from this perspective, I often refer to the modernist
          tradition- negotiating with the double binding of modernity, I
          position my practice in critical discourse with it. Methodologically,
          my work is directed towards materializing multiple vanishing points,
          different degrees, and units of measure for intensities, rhythms, and
          directions.
        </p>
      </template>
    </ImageComponent>
    <div id="news" class="news news-fix">
      <div class="row">
        <NewsItem
          v-for="item in items"
          :key="item.href"
          :href="item.href"
          :image="item.image"
          :text="item.text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from "@/ImageComponent";
import NewsItem from "@/NewsItem";

export default {
  name: "HomeNew",
  components: { NewsItem, ImageComponent },
  data() {
    return {
      items: [
        {
          href: "https://spomeniknaslobodata.mk/",
          image: "/assets/news/00.jpg",
          text: `<h2>spomenik</h2>
                 <h2>naslobodata</h2>
                 <h2>.mk</h2>`
        },
        {
          href:
            "https://restarted.hr/en/film/in-production-en/fatherland-a-monument-to-freedom/",
          image: "/assets/news/41.jpg",
          text: `<h2>RESTART</h2>
                <h2>FATHERLAND: A MONUMENT TO FREEDOM</h2>`
        },
        {
          href:
            "https://www.acinema.space/current-program/program-4-deciduous-symphysis",
          image: "/assets/news/39.png",
          text: `<h2>aCinema | Season 8</h2>
                 <h2>Summer Screening Series</h2>`
        },
        {
          href:
            "https://www.slobodenpecat.mk/kluch-kon-edno-ponezhno-pomalku-mazhestveno-i-poprizemno-chitanje-nosi-izlozhbata-chemerski-niz-crtezh-otposle/",
          image: "/assets/news/40.jpg",
          text: `<h2>A Key to a Tender, Grounded and Less Masculine Interpretation of Chemerski in the Exhibition 'Chemerski: Drawings Revisited'</h2>`
        },
        {
          href:
            "https://rewind.ac.uk/re_exhibit/re_exhibit-shaping-the-contemporary/",
          image: "/assets/news/38.jpg",
          text: `<h2>RE_EXHIBIT<br/>Shaping the Contemporary: Macedonian Video Art since 1984</h2>`
        },
        {
          href:
            "https://www.galerijaskuc.si/exhibition/here-but-somewhere-else/",
          image: "/assets/news/37.jpg",
          text: `<h2>Here, but somewhere else</h2>`
        },
        {
          href:
            "https://www.slobodenpecat.mk/umetnichki-krik-so-progresiven-izraz-nosi-noviot-festival-za-sovremena-umetnost-tanc-bunker-01/",
          image: "/assets/news/36.jpg",
          text: `<h2>Festival for Contemporary Art Dance Bunker 01</h2>`
        },
        {
          href:
            "https://structura.gallery/en/exhibitions/нестабилността-има-шанс-обществени-п/",
          image: "/assets/news/35.jpg",
          text: `<h2>PRECARITY HAS A CHANCE: PUBLIC SPACES IN MOVEMENT (TOWARD)</h2>`
        },
        {
          href: "https://meduza.mk/fem-101/7388",
          image: "/assets/news/34.jpg",
          text: `<h2>Meduza: Flying kites</h2>`
        },
        {
          href:
            "https://www.slobodenpecat.mk/grupnata-izlozhba-mesta-na-darezhlivost-transformativni-stanici-kje-bide-postavena-vo-ksp-centar-jadro",
          image: "/assets/news/32.jpg",
          text: `<h2>Spaces of Generosity- Transformative Stations</h2>`
        },
        {
          href:
            "https://residencyunlimited.org/programs/fatherland-a-monument-to-freedom-a-screening-by-elena-chermerska/",
          image: "/assets/news/31.jpg",
          text: `<h2>Residency Unlimited<br/>
                Fatherland: A Monument to Freedom</h2>
                <h5>screening by Elena Chemerska</h5>`
        },
        {
          href:
            "https://terapija.mk/event/terapija-x-fatherland-a-monument-to-freedom-oda-haliti-chorbika-ilina-angel",
          image: "/assets/news/30.png",
          text: `<h2>terapija x fatherland:a monument to freedom</h2>`
        }
      ]
    };
  },
  mounted() {
    const anchor = this.$router.currentRoute.hash;
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
      }
    });
  }
};
</script>

<style scoped></style>
