<template>
  <div>
    <ImageComponent
      :src="media[0].src"
      :alt="media[0].caption"
      @click="openGallery(0)"
    >
      <template v-slot:headline>
        <h2>these fragments i have shored against my ruins</h2>
        <h5>
          spatial composition, oil on canvas, sound, light, colour, metal.
        </h5>
        <h5>500 cm x 700 cm x 260 cm, 2020/21.</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              Negotiating with the Janus head of modernity and modernism, These
              Fragments I Have Shored Against My Ruins aims to open a
              conversation with the local and regional modernist legacy and
              touch upon certain painful spots in the way we as humans construct
              our image of history and the future in the present moment and
              specific cultural context.
            </p>
            <p>
              The work borrows the title from a verse from T.S. Eliot’s "The
              Waste Land", which might be read as the only beacon of hope in the
              otherwise darkest, last part of the poem- What the Thunder Said.
            </p>
            <p>
              The installation is an experiment in combining fragments of
              different material and spatial configuration in an attempt to open
              a space designated for knowledge to thought.
            </p>
            <p>
              The installation is compiled of a video work (A Promising Step,
              15’39, 2021) an architectural/sculptural object placed in space
              and a large painting (Meaning Escapes My Words, 310 cm x 180 cm,
              2020).
            </p>
            <p>
              The work is placed in relation to the former Taftalidze Local
              Community Building- itself a neglected modernist ruin. Built in
              the aftermath of the devastating earthquake in Skopje of 1963 to
              accommodate to the inhabitants of the settlement, the edifice- a
              work by the prominent architect Kiril Muratovski, is an
              architectural symbol of an era of a particular significance to the
              city; located in a residential area in Skopje, North Macedonia.
            </p>
            <h2>collaborators</h2>
            <ul class="standard-list no-padding-list">
              <li>Architect: Vlado Danailov</li>
              <li>Actress: Ivana Pavlakovikj</li>
              <li>Camera: Aleksandar Mižimakov</li>
              <li>Sound: Emi Ermilova</li>
            </ul>
          </div>
        </div>
      </template>
    </ImageComponent>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[1].caption"
          v-lazy="media[1].src"
          @click="openGallery(1)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[2].caption"
          v-lazy="media[2].src"
          @click="openGallery(2)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[3].caption"
          v-lazy="media[3].src"
          @click="openGallery(3)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[4].caption"
          v-lazy="media[4].src"
          @click="openGallery(4)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[5].caption"
          v-lazy="media[5].src"
          @click="openGallery(5)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[6].caption"
          v-lazy="media[6].src"
          @click="openGallery(6)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-left iframe-container mt-3">
        <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/536942500"
          class="responsive-iframe"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4 text-left">
        <p>
          A Promising Step (15’ 39’’, 2021) departs from the manifest of the
          group Denes which was formed in the 1950s in Macedonia from the
          necessity to create the basis for a genuine national contemporary art.
        </p>
        <p>
          Building upon some of the key issues raised in the manifest, it then
          proceeds to look into the human necessity of projectivity
          characteristic of the modernist era, while acknowledging the violence
          inscribed in the idea of progress as it has been won, conquered and
          realized throughout the whole of Western modernization.
        </p>
        <p>
          Looking at different rhythms embodied in species long ignored since
          they did not fit the idea of progress, the work aims to point to the
          possibility of dissolving established categories and blurring the
          boundary between human and environment, in the hopes of imagining a
          better life in the capitalist ruins.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[7].caption"
          v-lazy="media[7].src"
          @click="openGallery(7)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[8].caption"
          v-lazy="media[8].src"
          @click="openGallery(8)"
        />
      </div>
    </div>
    <Gallery :media="media" />
  </div>
</template>

<script>
import EventBus from "@/eventBus";
import ImageComponent from "@/ImageComponent";
import Gallery from "@/Gallery";

export default {
  name: "Fragments",
  components: {
    ImageComponent,
    Gallery
  },
  data() {
    return {
      media: [
        {
          src: "/assets/fragments/0.jpg",
          caption:
            "Installation view: These Fragments I Have Shored Against My Ruins, 2021.<br/>Taftalidze Local Community Building, Skopje, N. Macedonia."
        },
        {
          src: "/assets/fragments/1.jpg",
          caption:
            "Video still, A Promising Step,15’39, 2021. From These Fragments I Have Shored Against My Ruins, 2020/21."
        },
        {
          src: "/assets/fragments/7.jpg",
          caption:
            "Video still, A Promising Step,15’39, 2021. From These Fragments I Have Shored Against My Ruins, 2020/21."
        },
        {
          src: "/assets/fragments/8.jpg",
          caption:
            "Video still, A Promising Step,15’39, 2021. From These Fragments I Have Shored Against My Ruins, 2020/21."
        },
        {
          src: "/assets/fragments/9.jpg",
          caption:
            "Video still, A Promising Step,15’39, 2021. From These Fragments I Have Shored Against My Ruins, 2020/21."
        },
        {
          src: "/assets/fragments/10.jpg",
          caption:
            "Video still, A Promising Step,15’39, 2021. From These Fragments I Have Shored Against My Ruins, 2020/21."
        },
        {
          src: "/assets/fragments/11.jpg",
          caption:
            "Installation view: These Fragments I Have Shored Against My Ruins, 2021. MoCA, Skopje, N.Macedonia."
        },
        {
          src: "/assets/fragments/5.jpg",
          caption:
            "Installation view: These Fragments I Have Shored Against My Ruins, 2021. MoCA, Skopje, N.Macedonia."
        },
        {
          src: "/assets/fragments/6.jpg",
          caption:
            "Installation view: These Fragments I Have Shored Against My Ruins, 2021. MoCA, Skopje, N.Macedonia."
        }
      ]
    };
  },
  methods: {
    openGallery(index) {
      EventBus.$emit("open-gallery", index);
    }
  }
};
</script>

<style scoped></style>
