<template>
  <div>
    <ImageComponent
      :src="media[0].src"
      :alt="media[0].caption"
      @click="openGallery(0)"
    >
      <template v-slot:headline>
        <h2>
          iconography of the ritual:<br />
          swimming in the sticky lake
        </h2>
        <h5>
          in collaboration with ivana mirchevska and filip velkovski
        </h5>
        <h5>
          spatial installation, oil and acrylic on canvas, sculptures, sound,
          soil, rocks.
        </h5>
        <h5>2021</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              How did we get the unusual idea that nature, as opposed to
              culture, is ahistorical and timeless? Why have historicity and the
              possibility of intervention been granted only to language and
              culture, while matter is considered as passive and immutable or,
              at best, its potential for change is a derivative of language and
              culture? Are we really that fascinated by our own mind and
              self-awareness?
            </p>
            <p>
              Faced with the constant destruction of the environment and the
              relentless depletion of natural resources - we seek a new
              imaginative understanding of how we, on an emotional and sensory
              level, relate to the transformed landscapes of the host world.
            </p>
            <p>
              Diving into the imaginary of the water, we conceive the landscape
              as a sticky, dense and slippery lake, in which the refracted light
              turns into a slimy matter as it touches our skin. With our fingers
              we think the matter as images begin to spree.
            </p>
            <p>
              In the lake we swim together with mollusks, bird pearls, diving
              masks, ocular arms and many other species. Here we abandon our
              human bias, while engaging in a ritual in which the gaze becomes a
              gaze of togetherness that brings together, and not apart.
            </p>
            <p>Becoming many and none, we create worlds together.</p>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[1].caption"
                  v-lazy="media[1].src"
                  @click="openGallery(1)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[2].caption"
                  v-lazy="media[2].src"
                  @click="openGallery(2)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[3].caption"
                  v-lazy="media[3].src"
                  @click="openGallery(3)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[4].caption"
                  v-lazy="media[4].src"
                  @click="openGallery(4)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[5].caption"
                  v-lazy="media[5].src"
                  @click="openGallery(5)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[6].caption"
                  v-lazy="media[6].src"
                  @click="openGallery(6)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[7].caption"
                  v-lazy="media[7].src"
                  @click="openGallery(7)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[8].caption"
                  v-lazy="media[8].src"
                  @click="openGallery(8)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[9].caption"
                  v-lazy="media[9].src"
                  @click="openGallery(9)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[10].caption"
                  v-lazy="media[10].src"
                  @click="openGallery(10)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[11].caption"
                  v-lazy="media[11].src"
                  @click="openGallery(11)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[12].caption"
                  v-lazy="media[12].src"
                  @click="openGallery(12)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[13].caption"
                  v-lazy="media[13].src"
                  @click="openGallery(13)"
                />
              </div>
              <div class="col-12 col-lg-6 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[14].caption"
                  v-lazy="media[14].src"
                  @click="openGallery(14)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </ImageComponent>
    <Gallery :media="media" />
  </div>
</template>

<script>
import ImageComponent from "@/ImageComponent";
import Gallery from "@/Gallery";
import EventBus from "@/eventBus";

export default {
  name: "SwimmingLake",
  components: { Gallery, ImageComponent },
  methods: {
    openGallery(index) {
      EventBus.$emit("open-gallery", index);
    }
  },
  data() {
    return {
      media: [
        {
          src: "/assets/swimming/0.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/1.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/2.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/3.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/4.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/5.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/6.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/7.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/8.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/9.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/10.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/11.jpg",
          caption:
            "Installation view: Swimming in the Sticky Lake. Youth Cultural Center Gallery. Skopje. 2021."
        },
        {
          src: "/assets/swimming/12.jpg",
          caption: "Detail, Swimming in the Sticky Lake. 2021."
        },
        {
          src: "/assets/swimming/13.jpg",
          caption: "Detail, Swimming in the Sticky Lake. 2021."
        },
        {
          src: "/assets/swimming/14.jpg",
          caption: "Detail, Swimming in the Sticky Lake. 2021."
        }
      ]
    };
  }
};
</script>

<style scoped></style>
