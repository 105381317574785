<template>
  <div class="cv text-left">
    <div class="row">
      <div class="col-lg-4 col-12">
        <h3>contact</h3>
        <ul class="standard-list no-padding-list">
          <li>
            <svg
              style="margin-top: 10px; margin-right: 10px"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              class="bi bi-telephone"
              viewBox="0 0 16 16"
            >
              <path
                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
              />
            </svg>
            <span style="display: inline-table">
              +49 157 332 645 92<br />
              +389 71 681 889
            </span>
          </li>
          <li>
            <a href="mailto:info@elenachemerska.com" class="mr-2 text-black-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-envelope mr-1"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
                />
              </svg>
            </a>
            <a
              href="https://www.facebook.com/elena.chemerska"
              target="_blank"
              class="mr-2 text-black-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                />
              </svg>
            </a>
            <a
              href="https://vimeo.com/user70144746"
              target="_blank"
              class="mr-2 text-black-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M22 7.42c-.09 1.95-1.45 4.62-4.08 8.02C15.2 19 12.9 20.75 11 20.75c-1.15 0-2.14-1.08-2.95-3.25c-.55-1.96-1.05-3.94-1.61-5.92c-.6-2.16-1.24-3.24-1.94-3.24c-.14 0-.66.32-1.56.95L2 8.07c1-.87 1.96-1.74 2.92-2.61c1.32-1.14 2.31-1.74 2.96-1.8c1.56-.16 2.52.92 2.88 3.2c.39 2.47.66 4 .81 4.6c.43 2.04.93 3.04 1.48 3.04c.42 0 1.05-.64 1.89-1.97c.84-1.32 1.29-2.33 1.35-3.03c.12-1.14-.33-1.71-1.35-1.71c-.48 0-.97.11-1.48.33c.98-3.23 2.86-4.8 5.63-4.71c2.06.06 3.03 1.4 2.91 4.01z"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/elenachemerska"
              target="_blank"
              class="text-black-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-instagram mr-1"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                />
              </svg>
            </a>
          </li>
        </ul>
        <img
          class="img-fluid cv-image mt-4"
          alt="Elena Chemerska"
          v-lazy="'/assets/cv/01.jpeg'"
        />
        <div class="row mt-2 mb-4">
          <div class="col">
            Elena Chemerska (1991) Skopje, North Macedonia. Currently based
            between Berlin and Skopje.
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-12">
        <h3>education</h3>
        <ul class="standard-list">
          <li>
            2017- 2019 | Master of Art and Design in Fine Arts, Master Institute
            of Visual Cultures, AKV St. Joost Academy of Art and Design,
            ‘s-Hertogenbosch, the Netherlands.
          </li>
          <li>
            2011- 2015 | B.F.A. in Painting, Conservation and Restoration,
            University of “Ss. Cyril and Methodius” Faculty of Fine Arts,
            Department of Painting, Skopje, North Macedonia.
          </li>
          <li>
            22/01/2014 - 18/05/2014 | East Exit Scholarship by the Paul Getty
            Foundation recipient, Siena Art Institute, Siena, Italy. Program of
            Fine Arts, majors in Painting, Installation and Digital Multimedia
            and Art History.
          </li>
        </ul>
        <h3>selected solo & duo exhibitions and presentations</h3>
        <ul class="standard-list">
          <li>
            2023 | Boiling Body (in collaboration with Ivana Mirchevska), Dance
            Bunker 01 Contemporary Art Festival, Multimedia Center "Mala
            Stancica", National Gallery of R.N.Macedonia, Skopje, North
            Macedonia.
          </li>
          <li>
            2022 | “Fatherland: A Monument to Freedom”, screening by Elena
            Chemerska, KODA House Governors Island, New York, USA.
          </li>
          <li>
            2021 | These Fragments I Have Shored Against My Ruins, Local
            Community Building Taftalidze, Skopje, North Macedonia.
          </li>
          <li>
            2020 | Thresholds of No-Body in Particular (duo show in
            collaboration with Ivana Mirchevska), Firstborn Girl 8 Festival for
            Feminist Culture, Youth Cultural Centre, Skopje, North Macedonia.
          </li>
          <li>
            2020 | Speculative Manual on Haunted Thresholds (in collaboration
            with Ivana Mirchevska). Online screening, Cultural Center Jadro,
            Skopje, North Macedonia.
          </li>
          <li>
            2019 | Monumental Fluxus, (duo show with Astrid Ferringa. Youri
            Appelo, curator.) Besienderhuis (Expoplu), Nijmegen, the
            Netherlands.
          </li>
          <li>
            2019 | Monument to Freedom- Conversations, exhibition and book
            launch, Private Print Studio, Skopje, North Macedonia.
          </li>
        </ul>
        <h3>selected group exhibitions and presentations</h3>
        <ul class="standard-list">
          <li>
            2024 | "Host or Parasite: Boiling Body" screening, aCinema-
            experimental film and video screening series, Milwaukee, Wisconsin,
            USA.
          </li>
          <li>
            2024 | Shaping the Contemporary: Macedonian Video Art since 1984.
            (Jon Blackwood, curator). RE_ EXHIBIT Platform, Rewind, Dundee,
            Scotland.
          </li>
          <li>
            2023 | Here, but somewhere else. (Jana Stardelova, curator.) Škuc
            Gallery, Ljubljana, Slovenia.
          </li>
          <li>
            2023 | Youth Biennial 2023/ My Name is Merely Plain, ULUS Gallery,
            Belgrade, Serbia.
          </li>
          <li>
            2023 | Precarity has a Chance: Public Spaces in Movement (Towards).
            (Ivana Vaseva, curator), Structura Gallery, Sofia, Bulgaria.
          </li>
          <li>
            2022 | Spaces of Generosity- Transformative Stations, SCS Jadro,
            Skopje, North Macedonia.
          </li>
          <li>
            2022 | Late to the Party: Macedonian Performance on Video since
            1991, Museum Tinguely, Basel, Switzerland.
          </li>
          <li>
            2021 | Iconography of the Ritual: Swimming in the Sticky Lake (in
            collaboration with Ivana Mirchevska and Filip Velkovski), Youth
            Cultural Centre, Skopje, North Macedonia.
          </li>
          <li>
            2021 | Supernova Regeneration 2021, Denver, Colorado, USA.
          </li>
          <li>
            2021 | Speculative Manual on Haunted Thresholds (in collaboration
            with Ivana Mirchevska). Online screening, Museum of Now, Berlin,
            Germany. Forthcoming.
          </li>
          <li>
            2021 | Youth Biennial 2021/ Language in Common, ULUS Gallery,
            Belgrade, Serbia.
          </li>
          <li>
            2021 | Dislocations, online exhibition (Jon Blackwood, curator).
            Organized by Jon Blackwood and the Scottish Graduate School for the
            Arts & Humanities Summer School. Glasgow, Scotland.
          </li>
          <li>
            2021 | Ostrale Biennale 021, Robotron-Kantine, Dresden, Germany.
          </li>
          <li>
            2021 | XIII Biennial of Young Artists /ˈwɒndəlʌst/ (curated by
            Jovanka Popova, Blagoja Varoshanec, Iva Dimovski, Artan Sadiku),
            Museum of Contemporary Art, Skopje, North Macedonia.
          </li>
          <li>
            2021 | Speculative Manual in Haunted Thresholds (in collaboration
            with Ivana Mirchevska). Fuori Visioni - Festival Arte Contemporanea,
            Piacenza, Italy.
          </li>
          <li>
            2021 | Denes Award, online exhibition, Skopje, North Macedonia.
          </li>
          <li>
            2021 | Whatspace Cookbook, WHATSPACE Foundation, Tilburg, the
            Netherlands.
          </li>
          <li>
            2020 | The Hub of the ephemeral — hidden in sight (Marko Gutić
            Mižimakov, curator), GMK, Zagreb, Croatia.
          </li>
          <li>
            2020 | Да бидеме заедно, додека сме разделени (Jovanka Popova,
            curator), SCS Jadro, Skopje, North Macedonia.
          </li>
          <li>
            2019 | Easier to Breathe Underground, Plochnik Garages, Skopje,
            North Macedonia.
          </li>
          <li>
            2019 | Postcompost, MA Graduation Show (Florian Weigl, curator) .
            Master Institute for Visual Cultures, ‘s-Herogenbosch, the
            Netherlands.
          </li>
          <li>
            2018 | RESTART, Youth Cultural Center, Skopje, Macedonia.
          </li>
          <li>
            2018 | What Do We Dance For?, Gallery Nectar, Tbilisi, Georgia.
          </li>
          <li>
            2018 | Biennial of Young Artists (curated by Ivana Vaseva, Bojana
            Janeva Shemova and Vladimir Janchevski), Museum of Contemporary Art,
            Skopje, Macedonia.
          </li>
          <li>
            2018 | Transition, video screening program, Eye Museum, Amsterdam,
            the Netherlands.
          </li>
          <li>
            2017 | The Perfect Artist, AKTO Festival for Contemporary Art,
            Magaza Gallery, Bitola, Macedonia.
          </li>
          <li>
            2017 | Paratissima #4 Art Fair, Youth Cultural Center, Skopje,
            Macedonia.
          </li>
          <li>
            2017 | Skopje Fashion and Lifestyle Expo, Macedonian Opera and
            Ballet, Skopje, Macedonia.
          </li>
          <li>
            2016 | Paratissima #3 Art Fair, Youth Cultural Center, Skopje,
            Macedonia.
          </li>
          <li>
            2016 | Kula Turns Two, Cultural Center KULA, Skopje, Macedonia.
          </li>
          <li>
            2015 | Young Art, Mala Stanica, National Gallery of Macedonia,
            Skopje, Macedonia.
          </li>
          <li>
            2015 | University Occupation, Univesrity Ss Cyril and Methodious,
            Skopje, Macedonia.
          </li>
        </ul>
        <h3>residencies</h3>
        <ul class="standard-list">
          <li>
            2022 | Residency Unlimited, New York, USA.
            <a href="http://www.residencyunlimited.org/" target="_blank"
              >[link]</a
            >
          </li>
          <li>
            2020 | The Hub of the Ephemeral, GMK, Zagreb, Croatia.
            <a
              href="https://g-mk.hr/en/news/the-hub-of-the-ephemeral-hidden-in-sight-1/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>2020 | Online residency, SCS Jadro, Skopje, North Macedonia.</li>
        </ul>
        <h3>books published</h3>
        <ul class="standard-list">
          <li>
            2019 | Chemerska, Elena. Monument to Freedom- Conversations. 2019.
            Private Print. 336 p.
          </li>
        </ul>
        <h3>performances</h3>
        <ul class="standard-list">
          <li>
            2018 | Tools for Conviviality. Open Lesson- participant. Vierte Welt
            Theater, Berlin, Germany.
          </li>
        </ul>
        <h3>curating</h3>
        <ul class="standard-list">
          <li>
            2024 | Chemerski: Drawings Revisited. (Exhibiting artist: Gligor
            Chemerski. Curator: Elena Chemerska).Youth Cultural Centre, Skopje,
            North Macedonia.
          </li>
          <li>
            2022 | Terapija X Fatherland: A Monument to Freedom (Performing
            artists: Ilina Angel, Oda Haiti, Chorbika. Co-organizer: Elena
            Chemerska). Monument to Freedom, Kochani, North Macedonia.
          </li>
          <li>
            2018 | Salonica, City of Ghosts; Christians, Muslims and Jews
            1430-1950 (Performed by: Theater of Navigator Cvetko Director:
            Slobodan Unkovski. Curator: Elena Chemerska). Monument to Freedom,
            Kochani, North Macedonia.
          </li>
        </ul>
        <h3>conferences</h3>
        <ul class="standard-list">
          <li>
            2020 | Speaker, Architecture. Sculpture. Memory. The art of
            monuments of Yugoslavia 1945–1991 Webinar, hosted by Architectuul,
            Berlin, Germany.
          </li>
          <li>
            2020 | Speaker, Refreshing Memory Conference, Zagreb, Croatia -
            postponed due to COVID-19.
          </li>
        </ul>
        <h3>workshops conducted</h3>
        <ul class="standard-list">
          <li>
            2022 | Лета, лета Локубија, Monument to Freedom, Kochani, North
            Macedonia.
          </li>
          <li>
            2019 | Activating the archive: Think/ Talk/ Make, Master Institute
            of Visual Cultures, AKV St. Joost Academy of Art and Design. (In
            collaboration with anthropologist and artist Minke Nouwens)
            ‘s-Hertogenbosch, the Netherlands.
          </li>
          <li>
            2018 | RENDEZ VOUS # 4: A Chain of References- Reading group on
            Painting, Atlas Intiatief. (in collaboration with Rosemary de Booij,
            Isabel Cordeiro, Christa in Donthorst, Ruben Mols, Bas van den Hurk,
            Lisa van Sorge, Dagmar Marent, Catherine Maxime, Saskia Monshouwer),
            Breda, the Netherlands.
          </li>
          <li>
            2018 | Defy Defeat, Master Institute of Visual Cultures, AKV St.
            Joost Academy of Art and Design (in collaboration with artist
            Susanne Yusef) ‘s-Hertogenbosch, the Netherlands.
          </li>
        </ul>
        <h3>grants and nominations</h3>
        <ul class="standard-list">
          <li>
            2021 | Grant, Prince Claus Seed Award, Prince Claus Fund, Amsterdam,
            the Netherlands.
            <a
              href="https://princeclausfund.org/awardees/elena-chemerska"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            2021 | Grant, Denes Award, Skopje, North Macedonia.
            <a href="http://akto-fru.org/en/1841-2/" target="_blank">[link]</a>
          </li>
          <li>
            2019 | Nomination, Social Art Award, The Institute for Art and
            Innovation, Berlin, Germany.
          </li>
          <li>2017 | Grant, Eurolink Scholarship, Skopje, North Macedonia.</li>
          <li>
            2014 | Grant, East Exit Scholarship, Paul Getty Foundation, Siena,
            Italy.
          </li>
        </ul>
        <h3>publications</h3>
        <ul class="standard-list">
          <li>
            Martinovski, V. “A Precious Book about (the Monument to) Freedom”.
            Cultural Life. 3-4/2019.p.66-67.
          </li>
          <li>“Elena Chemerska”. Metropolis M. Eindexamen 2019. p. 23.</li>
          <li>
            Dimkov, T. “Conversation with Elena Chemerska, winner of the Denes
            Award for visual arts”, Културен печат. 07/09.05.2021.p.1
          </li>
          <li>
            Dimkov, T. “Progressive artistic expression at the new Festival for
            Contemporary Art Dance Bunker 01“, Културен печат. 2-3.12.2023
          </li>
          <li>
            Dimkov, T. " A Key to a Tender, Grounded and Less Masculine
            Interpretation of Chemerski in the Exhibition 'Chemerski: Drawings
            Revisited' ", Културен печат број 237, 29-30.6.2024. p.11
          </li>
          <li>
            Dimkov, T. “Being together while being apart”, Културен печат.
            20/21.06.2020.p.1.
          </li>
          <li>
            Korchak, P. (2021). Remembering Yugoslavia.
            <a
              href="https://rememberingyugoslavia.com/podcast-kocani-monument-to-freedom/"
              target="_blank"
              >[link]</a
            >
            April 19.
          </li>
          <li>
            Korchak, P. (2021). Remembering Yugoslavia.
            <a
              href="https://rememberingyugoslavia.com/podcast-all-monument-stage/"
              target="_blank"
              >[link]</a
            >
            March 01.
          </li>
          <li>
            Architectuul (2020, November 29) Architecture. Sculpture. Memory.
            The art of monuments of Yugoslavia 1945–1991. [webinar]
            <a
              href="https://www.facebook.com/events/667770060516565/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            PrivatePrint. (2020, February 13). PP talk: elena chemerska.
            <a
              href="https://www.youtube.com/watch?v=Bqm0nYrEUjs"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Stefanova, N. (2020, December 29) Fifteen minutes with Elena
            Chemerska.
            <a
              href="https://www.youtube.com/watch?v=7AFRTKlzSOg"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Stefanova, N. Monument to Freedom- Conversations by Elena Chemerska,
            2019.
            <a
              href="https://arhiva.telma.com.mk/spomenik-na-slobodata-razgovornik-od-elena-chemerska/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Lukanc, T. "O kartografijah pripadnosti", Dnevnik.27.12.2023.
            <a
              href="https://www.dnevnik.si/1043040422/kultura/vizualna-umetnost/o-kartografijah-pripadnosti"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Kocev, J. Елена Чемерска: „Лета, лета, Локубија“ е емотивно сеќавање
            на Кочани и на Споменикот на слободата, 2022.
            <a
              href="https://popup.mk/elena-chemerska-leta-leta-lokubija-e-emotivno-sekavane-na-kochani-i-na-spomenikot-na-slobodata"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            PCF, Elena Chemerska, Seed Award Recipient, 2021.
            <a
              href="https://princeclausfund.org/awardees/elena-chemerska"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            aCinema Space, Deciduous Symphysis, 2024.
            <a
              href="https://www.acinema.space/current-program/program-4-deciduous-symphysis"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            REWIND Artists’ Video, RE_EXHIBIT SHAPING THE CONTEMPORARY, 2024.
            <a
              href="https://rewind.ac.uk/re_exhibit/re_exhibit-shaping-the-contemporary/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            SAHA, Shaping the Contemporary: Macedonian Video Art since 1984,
            curated by Dr Jon Blackwood, 2024.
            <a
              href="https://saha.scot/shaping-the-contemporary-macedonian-video-art-since-1984-curated-by-dr-jon-blackwood/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Škuc Gallery, Here, but somewhere else, 2023.
            <a
              href="https://www.galerijaskuc.si/exhibition/here-but-somewhere-else/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Mutual Art, Here, but Somewhere Else, 2023.
            <a
              href="https://www.mutualart.com/Exhibition/Here--But-Somewhere-Else/8FC9B3BFCA87C07E"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Tiiiiitinc, Here, but somewhere else | 15. 12. 2023 - 11. 1. 2024 |
            Škuc Gallery, 2023.
            <a
              href="https://tiiiitinc.com/en/femoir/show/204/here-but-somewhere-else-15-12-2023-11-1-2024-skuc-gallery"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Tiiiiitinc, From the opening of the exhibition Here, but somewhere
            else, curated by Jana Stardelova, 2023.
            <a
              href="https://tiiiitinc.com/en/femoir/show/206/from-the-opening-of-the-exhibition-curated-by-jana-stardelova "
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Structura Gallery, PRECARITY HAS A CHANCE: PUBLIC SPACES IN MOVEMENT
            (TOWARD), 2023.
            <a
              href="https://structura.gallery/en/exhibitions/нестабилността-има-шанс-обществени-п/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            BTA, Contemporary Artists from North Macedonia Display Works in
            Sofia, 2023.
            <a
              href="https://www.bta.bg/en/news/balkans/475487-contemporary-artists-from-north-macedonia-display-works-in-sofia"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Cineuropa, The Beldocs Industry Days are ready to start. 2023.
            <a
              href="https://cineuropa.org/fr/newsdetail/442049/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            BelDocs, Beldocs Industry Days 2023, 2023.
            <a
              href="https://www.beldocs.rs/en/beldocs-industry-days-2023-2/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            DocsBarcelona, Industry Days 2023, 2023.
            <a
              href="https://docsbarcelona.com/activitats/speed-meetings-2023"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Governor's Island, FATHERLAND: A MONUMENT TO FREEDOM, 2022.
            <a
              href="https://www.govisland.com/things-to-do/events/fatherland-a-monument-to-freedom"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Tiiiiitinc, Thresholds of No-Body in Particular, 2020.
            <a
              href="https://tiiiitinc.com/en/femoir/show/81/thresholds-of-no-body-in-particular"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Porta 3, Елена Чемерска: Со овие одломки ги потпрев урнатините свои,
            2021.
            <a
              href="https://www.porta3.mk/elena-chemerska-so-ovie-odlomki-gi-potprev-urnatinite-svoi/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Okno, Elena Chemerska is the winner of the Denes Award for young
            visual artists 2021.
            <a href="https://okno.mk/node/89043" target="_blank">[link]</a>
          </li>
          <li>
            Okno, Monument to Freedom: Conversations; Book launch and an
            exhibition by Elena Chemerska, 2019.
            <a href="https://okno.mk/node/81000" target="_blank">[link]</a>
          </li>
          <li>
            FRU, Four finalists chosen for the DENES AWARD for young visual
            artist up to 35 in North Macedonia, 2019.
            <a
              href="http://akto-fru.org/en/our-finalists-chosen-for-the-denes-award-for-young-visual-artist-up-to-35-in-north-macedonia/"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Expoplu. Young Art Weekend: Monumental Fluxus @ Besienderhuis, 2019.
            <a
              href="https://expoplu.nl/archief/exposities/2019/elena-chemerska-astrid-feringa-young-art-weekend-monumental-fluxus-at-besiendershuis"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            PrivatePrint, Monument to Freedom- Conversations, 2019.
            <a
              href="http://privateprint.mk/mk/events/24-monument-to-freedom-conversation"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Municipality of Kochani, Monday, October 25- Promotion of the book
            Monument to Freedom- Conversations, 2019.
            <a href="https://bit.ly/2Tbwx7R" target="_blank">[link]</a>
          </li>
          <li>
            TVKanal8 (2019, October 28). Promotion of the book “monument to
            freedom- conversations” by elena chemerska.
            <a
              href="https://www.youtube.com/watch?v=VB2wmE2roko"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            TelevizijaKocani (2019, October 24) Promotion of the book “monument
            to freedom- conversations” by elena chemerska this friday.
            <a
              href="https://www.youtube.com/watch?v=UPmrr79gT4k"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            The Institute for Art and Innovation. Social Art Award 2019, 2019.
            <a
              href="https://social-art-award.org/award2019?contest=photo-detail&photo_id=1221"
              target="_blank"
              >[link]</a
            >
          </li>
          <li>
            Essay: ⥄_22: Top 19 for 2019 by Nebojsa Vilic, 30.12.2019.
            <a href="https://bit.ly/39X2rMb" target="_blank">[link]</a>
          </li>
          <li>
            Chemerska, E. Creating Spaces for Feeling Time, 2019.
            <a href="https://bit.ly/38SxLeX" target="_blank">[link]</a>
          </li>
          <li>
            The Open lesson “The tools for conviviality” Chto Delat Summer
            School, Berlin 2018.
            <a href="https://bit.ly/38V9h4S" target="_blank">[link]</a>
          </li>
          <li>
            Porta 3, New Web site about the Monument to Freedom, 2018.
            <a href="https://bit.ly/2T6Arip" target="_blank">[link]</a>
          </li>
          <li>
            Ajtovski, T. The theatre play “Salonica, City of Ghosts” filled the
            Monument to Freedom in Kochani, 2018.
            <a href="https://bit.ly/37Rzi3p" target="_blank">[link]</a>
          </li>
          <li>
            Chemerska, E. Salonica, City of Ghosts at the Monument to Freedom,
            2018. <a href="https://bit.ly/2HLuC4v" target="_blank">[link]</a>
          </li>
          <li>
            Chemerska, E. Скопје чува џебови со креативен набој, 2018.
            <a href="https://bit.ly/39WFRmN" target="_blank">[link]</a>
          </li>
        </ul>
        <h3>training</h3>
        <ul class="standard-list">
          <li>
            2024 | MakeCoProDox Forum, MakeDox Creative Documentary Film
            Festival, Skopje, North Macedonia.
          </li>
          <li>
            2024 | Jury Member, DB Mangelos Award for Young Visual Artists in
            Serbia, Belgrade, Serbia.
          </li>
          <li>
            2023-2024 | Conservator, Conservation and Restoration of the
            Monument to Freedom supported by the US Ambassadors Fund for
            Cultural Preservation, Kochani, North Macedonia.
          </li>
          <li>
            2023 | Beldocs Pitching Forum, Beldocs International Documentary
            Film Festival, Belgrade, Serbia.
          </li>
          <li>
            2023 | DocsBarcelona Speed Meetings Sessions, DocsBarcelona
            International Documentary Film Festival, Barcelona, Spain.
          </li>
          <li>
            2022 | Jury Member, XVI DENES Award for Young Visual Artists in
            North Macedonia, Skopje, North Macedonia.
          </li>
          <li>
            2022 | Feminist Storytelling, Home Workspace Program Ashkal Alwan,
            Beirut, Lebanon.
          </li>
          <li>
            04/21 | International EQ-Arts Reviewers Training, EQ Arts,
            Amsterdam, the Netherlands.
          </li>
          <li>
            12/19 | Visions from the River to the Sea and beyond, workshop by
            peace activist and journalist, organizer of the Great Return March
            in Palestine, Ahmed Abu Artema, Kunsthalle am Hamburger Platz,
            Berlin, Germany.
          </li>
          <li>
            10/19 | Member of EQ- Arts accreditation panel for MA programs at
            the Faculty of Fine Arts of Brno University of Technology (FFA BUT,
            Brno the Check Republic). Amsterdam, the Netherlands.
          </li>
          <li>
            07/19 | Disembedded Art and its Weird Language, course by writer,
            cultural critic and translator Boris Buden, SSAS Summer School,
            Stacion Center for Contemporary Art, Prishtina, Kosovo.
          </li>
          <li>
            05/19 | Member of EQ- Arts accreditation panel for the Lens Based
            Media, Experimental Publishing and Fine Art MA programs at the Piet
            Zwart Institute, Willem de Kooning Academy (Rotterdam, the
            Netherlands). EQ-Arts, Amsterdam, the Netherlands.
          </li>
          <li>
            03/19 | 09/19: Trainee, Grants and Collaborations department, Prince
            Claus Fund for Culture and Development, Amsterdam, the Netherlands.
          </li>
          <li>
            11/18 | Organization volunteer, Rijksakademie Open, Rijksakademie
            van beeldende kunsten, Amsterdam, the Netherlands.
          </li>
          <li>
            09/18-03/19 | Internhip, Rainbow Soulclub artist initiative of
            Saskia Janssen and George Korsmit at Blaka Watra Walk in Center,
            Amsterdam, the Netherlands.
          </li>
          <li>
            09/18-07/19 | Chair of Student Representative Council,Joint
            Consultative Committee, Master Institute of Visual Cultures, AKV St.
            Joost Academy of Art and Design, ‘s-Hertogenbosch, the Netherlands.
          </li>
          <li>
            08/18 | Go and Stop Progress!, Summer School by artist collective
            Chto Delat, Vierte Welt, Berlin, Germany.
          </li>
          <li>
            06/18-08/18 | Team member working on the design and development of a
            Grant proposal for conservation, restoration and revitalization of
            the Monument to Freedom in Kochani, Macedonia, with specific focus
            on the conservation of the mosaic friezes in total of 330 m2, aimed
            at their protection against further devastation and dilapidation.
            This Grant proposal has been presented for funding purposes to the
            Local and Regional Tourism Project, financed by EU/IPA2 funds and
            managed by the World Bank, Skopje, Macedonia.
          </li>
          <li>
            08/17 | The Perfect Artist, AKTO Festival for Contemporary Art,
            Bitola, North Macedonia.
          </li>
          <li>
            07/17 | Exhibiting: (En)countering Temporalities, course by curator
            and writer Rike Frank. SSAS Summer School, Stacion Center for
            Contemporary Art, Prishtina, Kosovo.
          </li>
          <li>
            2015-2016 | Organization Staff at GRAL Gallery, Skopje, Macedonia.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CV"
};
</script>

<style scoped>
.cv {
  font-size: 1.25rem;
}

.cv .cv-left-panel ul.standard-list li {
  margin-top: 0;
}

.cv ul.standard-list li {
  margin-top: 20px;
}

.cv ul.standard-list li a:hover {
  color: red !important;
}
</style>
