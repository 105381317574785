<template>
  <div class="row">
    <div class="col-12">
      <LightBox
        ref="lightbox"
        :media="media"
        :show-caption="showCaptionAndClose"
        :closable="showCaptionAndClose"
        :show-thumbs="false"
        :show-light-box="false"
        :show-footer-count="false"
        @onImageChanged="delayCaptionAndClose"
        @onOpened="delayCaptionAndClose"
      ></LightBox>
    </div>
  </div>
</template>

<script>
import LightBox from "vue-image-lightbox";
import EventBus from "@/eventBus";

export default {
  name: "Gallery",
  components: { LightBox },
  props: {
    media: Array
  },
  data() {
    return {
      showCaptionAndClose: false
    };
  },
  methods: {
    delayCaptionAndClose() {
      this.showCaptionAndClose = false;
      setTimeout(() => (this.showCaptionAndClose = true), 800);
    }
  },
  mounted() {
    EventBus.$on("open-gallery", index => {
      this.$refs.lightbox.showImage(index);
    });
  }
};
</script>

<style scoped></style>
