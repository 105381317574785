import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Thresholds from "@/views/Thresholds";
import Fatherland from "@/views/Fatherland";
import BoilingBody from "@/views/BoilingBody";
import CV from "@/views/CV";
import Fragments from "@/views/Fragments";
import Paintings from "@/views/Paintings";
import ImmediatePast from "@/views/ImmediatePast";
import Archive from "@/views/Archive";
import SwimmingLake from "@/views/SwimmingLake";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/cv",
    name: "CV",
    component: CV
  },
  {
    path: "/portfolio/thresholds",
    name: "Thresholds",
    component: Thresholds
  },
  {
    path: "/portfolio/fatherland",
    name: "Fatherland",
    component: Fatherland
  },
  {
    path: "/portfolio/boilingbody",
    name: "BoilingBody",
    component: BoilingBody
  },
  {
    path: "/portfolio/fragments",
    name: "Fragments",
    component: Fragments
  },
  {
    path: "/portfolio/immedaite-past",
    name: "ImmediatePast",
    component: ImmediatePast
  },
  {
    path: "/portfolio/paintings",
    name: "Paintings",
    component: Paintings
  },
  {
    path: "/portfolio/swimming-in-the-sticky-lake",
    name: "SwimmingLake",
    component: SwimmingLake
  },
  {
    path: "/archive",
    name: "Archive",
    component: Archive
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to) {
    if (to.hash && document.querySelector(to.hash)) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes
});

export default router;
