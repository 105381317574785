<template>
  <div class="col-12 col-lg-4 mt-4">
    <a :href="href" target="_blank" class="text-black-50 text-left">
      <div class="news-item">
        <div class="news-overlay">
          <div v-html="text"></div>
        </div>
        <img class="img-fluid" v-lazy="image" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "NewsItem",
  props: {
    href: {
      type: String
    },
    image: {
      type: String
    },
    text: {
      type: String
    }
  }
};
</script>

<style scoped></style>
