<template>
  <div>
    <ImageComponent
      :src="media[0].src"
      :alt="media[0].caption"
      @click="openGallery(0)"
    >
      <template v-slot:headline>
        <h2>thresholds of no-body in particular</h2>
        <h5>in collaboration with ivana mirchevska</h5>
        <h5>
          installation, sculpture, two channel video, video essay 8’30, sound.
        </h5>
        <h5>2020</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              Imagine a mutable architecture operating as open source software,
              whose materiality and virtuality remains available for perpetual
              modification and enhancement following the navigational impulse of
              the body. This is an impetus of the body in which affect is
              expressed spatially, as existing across and among people, not
              limited only within them. We enter this threshold (space) via
              prosthetic means. Generating image-bodies – avatars, we ask: what
              does it mean to inhabit such a body in such a reality? What are we
              seeing when we see not only images, but the image of ourselves,
              and not only that image, but that image seeing and interacting
              with all the other images of that realm? What are we feeling when
              we feel these images interact, when we feel our avatar interact
              with other images? Is it even possible to feel something in these
              instances? What is virtual affect? How does virtual affect come
              about?
            </p>
            <p>
              Nothing should be accepted as fixed, permanent, or ‘given’—neither
              material conditions nor social forms. Today we bear witness of a
              paradigm shift in our relation to the world and the other, of
              being confined to enclosed, often small spaces, and fixed in
              particular locations, we are reconsidering how our body economy is
              subjected to new forms of affective, political, geographical
              subjugation and alienation. Notwithstanding our avatars bear our
              semblance, yet by no means they are we, as they are a strategic
              attempt to deploy existing technologies to re-engineer the world
              for progrssive gender ends. Therefore this work is an attempt to
              join the xenofeminist call for deployment of reason, in which
              reason stands for the right of everyone to speak as no one in
              particular.
            </p>
            <h2>
              additional bio
            </h2>
            <p>
              Helena and Europa are the alter egos of Elena Chemerska and Ivana
              Mirchevska, respectively, which were born out of the experience
              with the Speculative Manual on Haunted Thresholds.
            </p>
            <p>
              Helena is a remembering and formulating machine, a goddess of the
              neural network. She is a freedom fighter and a terrorist for the
              independence of associations. Europa is the summoner of digital
              outcasts. She is a plateau and a space with many coordinates. She
              nourishes the eye and the mind. She is the screen, a noise and a
              voice: registers the emotional, cognitive and perceptual
              apparatuses of all cyber species.
            </p>
            <h2>collaborators</h2>
            <ul class="standard-list no-padding-list">
              <li>Architect: Vlado Danailov</li>
              <li>Sound: Jovan Bliznakovski</li>
            </ul>
          </div>
        </div>
      </template>
    </ImageComponent>
    <div class="row">
      <div class="col-12 col-lg-6 text-left mt-4">
        <img
          class="img-fluid"
          :alt="media[1].caption"
          v-lazy="media[1].src"
          @click="openGallery(1)"
        />
      </div>
      <div class="col-12 col-lg-6 text-left mt-4">
        <img
          class="img-fluid"
          :alt="media[2].caption"
          v-lazy="media[2].src"
          @click="openGallery(2)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 text-left mt-4">
        <video
          controls
          class="container-fluid threshold-video"
          poster="/assets/thresholds/video/02_poster.png"
        >
          <source src="/assets/thresholds/video/02.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="col-12 col-lg-6 text-left mt-4">
        <video
          controls
          class="container-fluid threshold-video"
          poster="/assets/thresholds/video/01_poster.png"
        >
          <source src="/assets/thresholds/video/01.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4 text-left mt-4">
        <img
          class="img-fluid"
          :alt="media[3].caption"
          v-lazy="media[3].src"
          @click="openGallery(3)"
        />
      </div>
      <div class="col-12 col-lg-4 text-left mt-4">
        <img
          class="img-fluid"
          :alt="media[4].caption"
          v-lazy="media[4].src"
          @click="openGallery(4)"
        />
        <img
          class="img-fluid mt-4"
          :alt="media[5].caption"
          v-lazy="media[5].src"
          @click="openGallery(5)"
        />
      </div>
      <div class="col-12 col-lg-4 text-left mt-4">
        <img
          class="img-fluid"
          :alt="media[6].caption"
          v-lazy="media[6].src"
          @click="openGallery(6)"
        />
        <img
          class="img-fluid mt-4"
          :alt="media[7].caption"
          v-lazy="media[7].src"
          @click="openGallery(7)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-left iframe-container mt-4">
        <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/506853841"
          class="responsive-iframe"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-left mt-4">
        <img
          class="img-fluid"
          :alt="media[8].caption"
          v-lazy="media[8].src"
          @click="openGallery(8)"
        />
      </div>
    </div>
    <Gallery :media="media" />
  </div>
</template>

<script>
import ImageComponent from "@/ImageComponent";
import Gallery from "@/Gallery";
import EventBus from "@/eventBus";

export default {
  name: "Thresholds",
  components: { ImageComponent, Gallery },
  data() {
    return {
      media: [
        {
          src: "/assets/thresholds/00.jpg",
          caption:
            "Helena and Europa. Thresholds of no-body in particular. 2020. Collaboration between Elena Chemerska and Ivana Mirchevska."
        },
        {
          src: "/assets/thresholds/1.png",
          caption:
            "Venus, 2020. Plaster, clay, polyester, carpet. 140 cm x 80 cm x 80 cm."
        },
        {
          src: "/assets/thresholds/2.jpg",
          caption:
            "Installation view: Thresholds of no-body in particular. 2020. YCC Gallery Skopje, Firstborn Girl #8."
        },
        {
          src: "/assets/thresholds/3.jpg",
          caption:
            "Installation view: Thresholds of no-body in particular. 2020. YCC Gallery Skopje, Firstborn Girl #8."
        },
        {
          src: "/assets/thresholds/4.jpg",
          caption:
            "Installation view: Thresholds of no-body in particular. 2020. YCC Gallery Skopje, Firstborn Girl #8."
        },
        {
          src: "/assets/thresholds/6.png",
          caption:
            "Installation view: Thresholds of no-body in particular. 2020. YCC Gallery Skopje, Firstborn Girl #8."
        },
        {
          src: "/assets/thresholds/5.png",
          caption:
            "Installation view: Thresholds of no-body in particular. 2020. YCC Gallery Skopje, Firstborn Girl #8."
        },
        {
          src: "/assets/thresholds/7.png",
          caption:
            "Installation view: Thresholds of no-body in particular. 2020. YCC Gallery Skopje, Firstborn Girl #8."
        },
        {
          src: "/assets/thresholds/8.jpg",
          caption:
            "Speculative Manual for Haunted Thresholds. Chapter 3: In Praise of Labour. 2020. Video still, color, sound. Video duration: 00:08:15."
        }
      ]
    };
  },
  methods: {
    openGallery(index) {
      EventBus.$emit("open-gallery", index);
    }
  }
};
</script>

<style scoped></style>
