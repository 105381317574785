<template>
  <div>
    <ImageComponent
      :src="media[0].src"
      :alt="media[0].caption"
      @click="openGallery(0)"
    >
      <template v-slot:headline>
        <h2>paintings</h2>
        <h5>2017 - ongoing</h5>
        <h5>
          <a href="mailto:info@elenachemerska.com"
            >contact directly for prices</a
          >
        </h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              An overview of selected paintings that I made from 2017 onwards.
              Many of them form parts of larger, more elaborated projects, while
              others I view as separate works on their own.
            </p>
            <p>
              I am deeply interested in the time painting opens; time implied,
              represented, embodied or actual. I view paintings as processes
              where time is marked with the paint itself; processes which, at
              the same time exist in the permanent tension between the pulling
              towards radical autonomy on the one side, and the fact that they
              are a part of a heteronomous network of histories, modes of
              production and commodification on the other.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[1].caption"
              v-lazy="media[1].src"
              @click="openGallery(1)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[2].caption"
              v-lazy="media[2].src"
              @click="openGallery(2)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[3].caption"
              v-lazy="media[3].src"
              @click="openGallery(3)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[4].caption"
              v-lazy="media[4].src"
              @click="openGallery(4)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[5].caption"
              v-lazy="media[5].src"
              @click="openGallery(5)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[6].caption"
              v-lazy="media[6].src"
              @click="openGallery(6)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[7].caption"
              v-lazy="media[7].src"
              @click="openGallery(7)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[8].caption"
              v-lazy="media[8].src"
              @click="openGallery(8)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[9].caption"
              v-lazy="media[9].src"
              @click="openGallery(9)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[10].caption"
              v-lazy="media[10].src"
              @click="openGallery(10)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4 mt-4">
            <img
              class="img-fluid"
              :alt="media[11].caption"
              v-lazy="media[11].src"
              @click="openGallery(11)"
            />
          </div>
          <div class="col-12 col-lg-4 mt-4">
            <img
              class="img-fluid"
              :alt="media[12].caption"
              v-lazy="media[12].src"
              @click="openGallery(12)"
            />
          </div>
          <div class="col-12 col-lg-4 mt-4">
            <img
              class="img-fluid"
              :alt="media[13].caption"
              v-lazy="media[13].src"
              @click="openGallery(13)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[14].caption"
              v-lazy="media[14].src"
              @click="openGallery(14)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[15].caption"
              v-lazy="media[15].src"
              @click="openGallery(15)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[16].caption"
              v-lazy="media[16].src"
              @click="openGallery(16)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[17].caption"
              v-lazy="media[17].src"
              @click="openGallery(17)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[18].caption"
              v-lazy="media[18].src"
              @click="openGallery(18)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[19].caption"
              v-lazy="media[19].src"
              @click="openGallery(19)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[20].caption"
              v-lazy="media[20].src"
              @click="openGallery(20)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[21].caption"
              v-lazy="media[21].src"
              @click="openGallery(21)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[22].caption"
              v-lazy="media[22].src"
              @click="openGallery(22)"
            />
          </div>
        </div>
      </template>
    </ImageComponent>
    <Gallery :media="media" />
  </div>
</template>

<script>
import ImageComponent from "@/ImageComponent";
import Gallery from "@/Gallery";
import EventBus from "@/eventBus";

export default {
  name: "Paintings",
  components: { ImageComponent, Gallery },
  data() {
    return {
      media: [
        {
          src: "/assets/paintings/00.jpg",
          caption:
            "Immediate Past Becoming Ancient History Or On Memory - Detail. Mixed media on canvas, 190 cm x 150 cm. 2021."
        },
        {
          src: "/assets/paintings/20.jpg",
          caption:
            "Rrrrrghhrrrra! Oil paint on canvas. 180 cm x 140 cm. 2023/24"
        },
        {
          src: "/assets/paintings/19.jpg",
          caption: "PSCHOUUUU. Mixed media on canvas. 225cm x 100 cm. 2023."
        },
        {
          src: "/assets/paintings/22.jpg",
          caption:
            "Ffff....ff....F! .bones. F..fF. . f.  ffff . . . F. Oil paint on canvas. 225 cm x 100 cm. 2024."
        },
        {
          src: "/assets/paintings/21.jpg",
          caption:
            "Immediate Past Becoming Ancient History or On Memory II. Mixed Media on Canvas. 190 cm x 145 cm. 2024."
        },
        {
          src: "/assets/paintings/1.jpg",
          caption:
            "Immediate Past Becoming Ancient History Or On Memory. Mixed media on canvas, 190 cm x 150 cm. 2021."
        },
        {
          src: "/assets/paintings/18.jpg",
          caption:
            "Immediate Past Becoming Ancient History. Mixed media on canvas, 150 cm x 95 cm, 2021."
        },
        {
          src: "/assets/paintings/2.jpg",
          caption:
            "Water Lilies and Reverberating Screams. Mixed media on canvas, 150 cm x 95 cm, 2021."
        },
        {
          src: "/assets/paintings/3.jpg",
          caption:
            "Our Lady of the Lake or Strawberry Fields. Oil paint on canvas, 110 cm x  90 cm, 2020."
        },
        {
          src: "/assets/paintings/4.jpg",
          caption: "I See Water.  Mixed media on canvas, 120 cm x 100 cm, 2020."
        },
        {
          src: "/assets/paintings/5.jpg",
          caption: "Swim! Oil paint on canvas, 50cm x 50cm. 2020."
        },
        {
          src: "/assets/paintings/6.jpg",
          caption:
            "Study for Fatherland; A Monument to Freedom. Oil paint on canvas, 50 cm x 50 cm, 2018."
        },
        {
          src: "/assets/paintings/7.jpg",
          caption:
            "Study for Fatherland; A Monument to Freedom. Oil paint on canvas, 50 cm x 50 cm, 2018."
        },
        {
          src: "/assets/paintings/8.jpg",
          caption:
            "Study for Fatherland; A Monument to Freedom. Oil paint on canvas, 50 cm x 50 cm, 2018."
        },
        {
          src: "/assets/paintings/9.jpg",
          caption:
            "Fatherland; A Monument to Freedom. Oil paint on canvas, 150 cm x 150 cm, 2019/20."
        },
        {
          src: "/assets/paintings/10.jpg",
          caption:
            "Untitled. Oil paint on canvas, 190 cm x 150 cm, 2019. From Fatherland; A Monument to Freedom."
        },
        {
          src: "/assets/paintings/11.jpg",
          caption:
            "Where the Ghosts and the Living Gather for a Drink. Oil paint on canvas, 190 cm x 150 cm, 2018. From Fatherland; A Monument to Freedom."
        },
        {
          src: "/assets/paintings/13.jpg",
          caption:
            "Frieze Dedicated to the Victims, 1982. Oil paint on canvas, 100 cm x 72 cm, 2019. From Fatherland; A Monument to Freedom."
        },
        {
          src: "/assets/paintings/12.jpg",
          caption:
            "Column of Resistance. Oil paint on canvas, 200 cm x 120 cm, 2019/20. From Fatherland; A Monument to Freedom."
        },
        {
          src: "/assets/paintings/16.jpg",
          caption: "Self-portrait. Oil paint on canvas, 50cm x 50cm, 2017."
        },
        {
          src: "/assets/paintings/17.jpg",
          caption: "I remember. Oil paint on canvas, 50cm x 50cm, 2017."
        },
        {
          src: "/assets/paintings/14.jpg",
          caption:
            "Gea; A Radiant Smile. Mixed media on canvas, 120 cm x 110 cm, 2017."
        },
        {
          src: "/assets/paintings/15.jpg",
          caption: "Substitutes. Oil paint on canvas, 70cm x 50cm, 2017."
        }
      ]
    };
  },
  methods: {
    openGallery(index) {
      EventBus.$emit("open-gallery", index);
    }
  }
};
</script>

<style scoped></style>
