<template>
  <div id="app">
    <Navbar />
    <div class="page-wrapper text-black-50">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<style>
@import "~vue-image-lightbox/dist/vue-image-lightbox.min.css";
@import "assets/css/lightbox-override.css";
@import "assets/css/global.css";
@import "~vueperslides/dist/vueperslides.css";
@font-face {
  font-family: "Monda";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/TK3tWkYFABsmjspvPho7vA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Monda";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/TK3tWkYFABsmjsphPho.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
</style>
<script>
import Navbar from "@/Navbar";
import Footer from "@/Footer";
export default {
  components: { Footer, Navbar }
};
</script>
