<template>
  <div>
    <ImageComponent
      :src="media[0].src"
      :alt="media[0].caption"
      @click="openGallery(0)"
    >
      <template v-slot:headline>
        <h2>immediate past becoming ancient history or on memory</h2>
        <h5>the hub of the ephemeral - hidden in sight</h5>
        <h5>gmk, zagreb, croatia</h5>
        <h5>2020</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              Residency program participants: Ivana Mirchevska, Natasha
              Nedelkova & Elena Chemerska (Skopje, North Macedonia).
              <br />
              Curated by Marko Gutić Mižimakov
            </p>
            <p class="mt-5">
              "TIME PASSES TIME does not pass. Time all but passes. Time usually
              passes. Time passing and gazing. Time has no gaze. Time as
              perseverance. Time as hunger. Time in a natural way. Time when you
              were six the day a mountain. Mountain time. Time I don’t remember.
              Time for a dog in an alley caught in the beam of your flashlight.
              Time not a video." from Red Doc> by Anne Carson.
            </p>
            <p>
              Sprouting from a persistent need to share our artistic processes
              and find a possibility for contact even now when every such
              potential seems to slip out of the domain of the possible or
              reachable into the domain of total biopolitical control we will
              try and meet for a three week long residency in Zagreb during
              September of 2020.
            </p>
            <p>
              Using 7ica space as a studio we (presume we) will give space to
              topics and practices which occupy us. Among else these are: time
              as a medium in which things are not things but processes, time as
              extended space, the phenomena of déjà vu as a moment of displaced
              temporality, YugoFuturism and the echoes of modernism
              reverberating from the cracks in the iPhone screens hidden in the
              pockets of cultural workers, the topology of the particular
              against the universal, exercises in (SOMA)tic poetry,
              representation of the sensible, power dynamics and the affective.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[1].caption"
              v-lazy="media[1].src"
              @click="openGallery(1)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[2].caption"
              v-lazy="media[2].src"
              @click="openGallery(2)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[3].caption"
              v-lazy="media[3].src"
              @click="openGallery(3)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[4].caption"
              v-lazy="media[4].src"
              @click="openGallery(4)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[5].caption"
              v-lazy="media[5].src"
              @click="openGallery(5)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <vueper-slides
              :fixed-height="true"
              class="vueper-immediate-past"
              autoplay
              duration="3000"
              lazy
              lazy-load-on-drag
            >
              <vueper-slide
                v-for="(slide, i) in slides"
                :key="i"
                :title="slide.title"
                :content="slide.content"
                :image="slide.image"
              />
            </vueper-slides>
          </div>
        </div>
      </template>
    </ImageComponent>
    <Gallery :media="media" />
  </div>
</template>

<script>
import ImageComponent from "@/ImageComponent";
import Gallery from "@/Gallery";
import EventBus from "@/eventBus";
import { VueperSlide, VueperSlides } from "vueperslides";

export default {
  name: "ImmediatePast",
  components: {
    ImageComponent,
    Gallery,
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      slides: [
        {
          image: "/assets/immediate/11.jpg"
        },
        {
          image: "/assets/immediate/12.jpg"
        },
        {
          image: "/assets/immediate/13.jpg"
        },
        {
          image: "/assets/immediate/14.jpg"
        },
        {
          image: "/assets/immediate/15.jpg"
        },
        {
          image: "/assets/immediate/16.jpg"
        }
      ],
      media: [
        {
          src: "/assets/immediate/00.jpg",
          caption:
            "Immediate Past Becoming Ancient History. 2020. Video still, color, sound. 6'45."
        },
        {
          src: "/assets/immediate/01.jpg",
          caption:
            "Installation View: Immediate Past Becoming Ancient History, 2020. GMK, Zagreb, Croatia."
        },
        {
          src: "/assets/immediate/02.jpg",
          caption:
            "Installation View: Immediate Past Becoming Ancient History, 2020. GMK, Zagreb, Croatia. "
        },
        {
          src: "/assets/immediate/03.jpg",
          caption:
            "Immediate Past Becoming Ancient History or on Memory. Marker on paper, 21 cm x 15 cm, 2020. "
        },
        {
          src: "/assets/immediate/04.jpg",
          caption:
            "Immediate Past Becoming Ancient History or on Memory. Marker on paper, 110 cm x 80 cm. 2020."
        },
        {
          src: "/assets/immediate/05.jpg",
          caption:
            "Immediate Past Becoming Ancient History or on Memory. Oil on canvas, 190 cm x 150 cm. 2021."
        }
      ]
    };
  },
  methods: {
    openGallery(index) {
      EventBus.$emit("open-gallery", index);
    }
  }
};
</script>

<style scoped></style>
