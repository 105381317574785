<template>
  <div>
    <div class="row">
      <NewsItem
        v-for="item in items"
        :key="item.href"
        :href="item.href"
        :image="item.image"
        :text="item.text"
      />
    </div>
  </div>
</template>

<script>
import NewsItem from "@/NewsItem";

export default {
  name: "Archive",
  components: { NewsItem },
  data() {
    return {
      items: [
        {
          href:
            "https://popup.mk/elena-chemerska-leta-leta-lokubija-e-emotivno-sekavane-na-kochani-i-na-spomenikot-na-slobodata",
          image: "/assets/news/29.png",
          text: `<h2>ELENA CHEMERSKA: FLY LOKUBIJA FLY</h2>`
        },
        {
          href: "https://residencyunlimited.org/residencies/elena-chemerska/",
          image: "/assets/news/20.jpg",
          text: `<h2>Residency Unlimited</h2>`
        },
        {
          href: "https://bijenalemladih.ulus.rs/en/portfolio-item/ivana-elena",
          image: "/assets/news/33.jpg",
          text: `<h2>YOUTH BIENNIAL ULUS</h2>`
        },
        {
          href: "https://youtu.be/vLKf6oz0fPY",
          image: "/assets/news/28.jpg",
          text: `<h2>CITY SCOPE CONVERSATIONS: Elena Chemerska</h2>`
        },
        {
          href: "https://mkc.mk/event/ikonografija-na-ritualot",
          image: "/assets/news/27.jpg",
          text: `<h2>Swimming in the sticky lake</h2>`
        },
        {
          href: "https://princeclausfund.org/awardees/elena-chemerska",
          image: "/assets/news/26.jpg",
          text: `<h2>Prince Claus Seed Award</h2>`
        },
        {
          href:
            "https://www.novamakedonija.com.mk/zivot/kultura/%d0%bc%d0%b0%d0%ba%d0%b5%d0%b4%d0%be%d0%bd%d1%81%d0%ba%d0%b0%d1%82%d0%b0-%d1%83%d0%bc%d0%b5%d1%82%d0%bd%d0%b8%d1%86%d0%b0-%d0%b5%d0%bb%d0%b5%d0%bd%d0%b0-%d1%87%d0%b5%d0%bc%d0%b5%d1%80%d1%81%d0%ba/",
          image: "/assets/news/6.jpg",
          text: `<h2>Elena Chemerska at the Dresden Biennale</h2>`
        },
        {
          href: "https://www.ostrale.de/en/Archive/OSTRALE-O21.html",
          image: "/assets/news/25.jpg",
          text: `<h2>OSTRALE Biennale 021</h2>`
        },
        {
          href:
            "https://slobodneveze.wordpress.com/2021/04/30/ostrale-biennale-o21-breathturn",
          image: "/assets/news/24.jpg",
          text: `<h2>OSTRALE BIENNALE O21: BREATHTURN</h2>`
        },
        {
          href:
            "https://www.slobodenpecat.mk/vizuelna-umetnost-razgovor-so-elena-chemerska-dobitnichka-na-nagradata-denes",
          image: "/assets/news/20.jpg",
          text: `<h2>Visual Arts: Conversaton with Elena Chemerska, winner of the "DENES" Award.</h2>`
        },
        {
          href:
            "https://www.slobodenpecat.mk/koloriten-spektar-od-boi-na-13-bienale-na-mladi-umetniczi-vo-msu/",
          image: "/assets/news/23.jpg",
          text: `<h2>13th Biennial of Young Artists at the MoCA Skopje</h2>`
        },
        {
          href:
            "https://www.radiomof.mk/elena-chemerska-dobitnichka-na-nagradata-denes-za-mladi-vizuelni-umetnici",
          image: "/assets/news/21.jpg",
          text: `<h2>Elena Chemerska winner of the "DENES" Award for young visual artists</h2>`
        },
        {
          href:
            "https://mia.mk/elena-chemerska-wins-denes-young-visual-artist-award/?lang=en",
          image: "/assets/news/20.jpg",
          text: `<h2>Elena Chemerska wins Denes Young Visual Artist Award</h2>`
        },
        {
          href: "http://www.fuorivisioni.com/",
          image: "/assets/news/22.jpg",
          text: `<h2>FUORI VISIONI</h2>
                 <h5>Festival di Arte Contemporanea</h5>`
        },
        {
          href:
            "https://rememberingyugoslavia.com/podcast-kocani-monument-to-freedom",
          image: "/assets/news/19.jpg",
          text: `<h2>Remembering Yugoslavia Podcast</h2>
                 <h5>Episode #32</h5>
                 <h5>MOTHER FREEDOM, FATHER LAND: THE REVIVAL OF KOČANI'S MONUMENT TO FREEDOM</h5>`
        },
        {
          href: "https://rememberingyugoslavia.com/podcast-all-monument-stage",
          image: "/assets/news/19.jpg",
          text: `<h2>Remembering Yugoslavia Podcast</h2>
                 <h5>Episode #27</h5>
                 <h5>All the Monument‘s a Stage</h5>`
        },
        {
          href: "https://www.facebook.com/Architectuul/videos/1264440600602654",
          image: "/assets/news/18.jpg",
          text: `<h2>Architectuul Webinar</h2>
                 <h5>Architecture. Sculpture. Memory. The art of monuments in Yugoslavia 1945-1991.</h5>`
        },
        {
          href: "http://kontrapunkt-mk.org/trchanje-vo-mesto/",
          image: "/assets/news/17.jpg",
          text: `<h2>PUNKTUM / Trcanje vo mesto</h2>`
        },
        {
          href:
            "https://g-mk.hr/en/news/the-hub-of-the-ephemeral-hidden-in-sight-1/",
          image: "/assets/news/16.jpg",
          text: `<h2>
                  The Hub of the ephemeral-hidden in sight(1.)
                </h2>`
        },
        {
          href:
            "https://tiiiitinc.com/femoar/prikaz/81/nichii-pragovi-konkretno",
          image: "/assets/news/15.jpg",
          text: `<h2>
                  Thresholds of No-Body in Particular
                </h2>`
        },
        {
          href:
            "https://tiiiitinc.com/en/events/show/148/thresholds-of-no-body-in-particular",
          image: "/assets/news/14.jpg",
          text: `<h2>
                  Firstborn girl 8 | Women are minority
                </h2>`
        },
        {
          href:
            "https://soundcloud.com/user-963981922/prva-ubava-rabota-vo-472451997",
          image: "/assets/news/13.jpg",
          text: `<h2>
                  PRVA UBAVA RABOTA VO DENOT
                </h2>`
        },
        {
          href: "https://licevlice.mk/doza-vesti/articles/114",
          image: "/assets/news/12.jpg",
          text: `<h2>
                  Being together, while being apart
                </h2>`
        },
        {
          href: "https://www.youtube.com/watch?v=Bqm0nYrEUjs",
          image: "/assets/news/11.jpg",
          text: `<h2>PP Talk Elena Chemerska</h2>`
        },
        {
          href:
            "http://akto-fru.org/en/our-finalists-chosen-for-the-denes-award-for-young-visual-artist-up-to-35-in-north-macedonia/",
          image: "/assets/news/10.jpg",
          text: `<h2>
                FOUR FINALISTS CHOSEN FOR THE DENES AWARD FOR YOUNG VISUAL
                ARTIST UP TO 35 IN NORTH MACEDONIA
              </h2>`
        },
        {
          href:
            "https://www.intonijmegen.com/blijf-op-de-hoogte/verhaal/young-art-weekend-maak-kennis-met-jong-talent",
          image: "/assets/news/9.jpg",
          text: `<h2>Young Art Weekend: fresh art by young talent</h2>`
        },
        {
          href:
            "https://expoplu.nl/archief/exposities/2019/elena-chemerska-astrid-feringa-young-art-weekend-monumental-fluxus-at-besiendershuis",
          image: "/assets/news/8.jpg",
          text: `<h2>Young Art Weekend: Monumental Fluxus</h2>`
        },
        {
          href: "https://www.youtube.com/watch?v=VB2wmE2roko",
          image: "/assets/news/7.jpg",
          text: `<h2>
                "Monument to Freedom - Conversations" Book Launch at the Monument to Freedom in Kochani
              </h2>`
        },
        {
          href:
            "https://popup.mk/%D0%B5%D0%BB%D0%B5%D0%BD%D0%B0-%D1%87%D0%B5%D0%BC%D0%B5%D1%80%D1%81%D0%BA%D0%B0-%D0%B4%D0%B0-%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D0%B2%D0%B0%D0%BC%D0%B5-%D0%BF%D1%80%D0%BE%D1%81%D1%82%D0%BE%D1%80%D0%B8",
          image: "/assets/news/6.jpg",
          text: `<h2>
                Creating spaces for filling time
              </h2>`
        },
        {
          href:
            "https://www.radiomof.mk/spomenik-na-slobodata-razgovornik-kniga-i-izlozhba-na-elena-chemerska",
          image: "/assets/news/5.jpg",
          text: `<h2>
                Monument to freedom - Conversations
              </h2>
              <h5>
                book launch and exhibition by Elena Chemerska
              </h5>`
        },
        {
          image: "/assets/news/4.jpg",
          text: `<h2>
                Postcompost, 2019
              </h2>
              <h5>
                MA Graduation Show, Den Bosch, Netherlands
              </h5>`
        },
        {
          href:
            "https://www.porta3.mk/nov-web-site-za-spomenikot-na-slobodata-vo-kochani",
          image: "/assets/news/3.jpg",
          text: `<h2>
                A new website about the Monument to Freedom in Kochani
              </h2>`
        },
        {
          href:
            "https://popup.mk/%d1%81%d0%be%d0%bb%d1%83%d0%bd-%d0%b3%d1%80%d0%b0%d0%b4-%d0%bd%d0%b0-%d0%b4%d1%83%d1%85%d0%be%d0%b2%d0%b8-%d1%81%d0%b5-%d0%b8%d0%b7%d0%b2%d0%b5%d0%b4%d1%83%d0%b2%d0%b0/",
          image: "/assets/news/2.jpg",
          text: `<h2>
                "Salonica, city of ghosts" will perform at the Monument to Freedom in Kochani
              </h2>`
        },
        {
          href:
            "https://popup.mk/%D0%B5%D0%BB%D0%B5%D0%BD%D0%B0-%D1%87%D0%B5%D0%BC%D0%B5%D1%80%D1%81%D0%BA%D0%B0-%D1%81%D0%BA%D0%BE%D0%BF%D1%98%D0%B5-%D1%87%D1%83%D0%B2%D0%B0-%D1%9F%D0%B5%D0%B1%D0%BE%D0%B2%D0%B8-%D1%81%D0%BE-%D0%BA/",
          image: "/assets/news/1.jpg",
          text: `<h2>
                Skopje's secret pockets of creative potential
              </h2>`
        }
      ]
    };
  }
};
</script>

<style scoped></style>
