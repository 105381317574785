<template>
  <div class="sticky-top">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light align-content-end"
    >
      <router-link class="navbar-brand" :to="{ name: 'Home' }"
        >elena chemerska
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="homeDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              home
            </a>
            <div class="dropdown-menu" aria-labelledby="homeDropdown">
              <router-link
                class="dropdown-item"
                :to="{ name: 'Home' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >about
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'Home', hash: '#news' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >news
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'Archive' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >archive
              </router-link>
            </div>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              :to="{ name: 'CV' }"
              >cv
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="portfolioDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              selected works
            </a>
            <div class="dropdown-menu" aria-labelledby="portfolioDropdown">
              <router-link
                class="dropdown-item"
                :to="{ name: 'Fatherland' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >fatherland - monument to freedom 2017-ongoing
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'BoilingBody' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >host or parasite: boiling body 2023
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'SwimmingLake' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >swimming in the sticky lake 2021
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'Fragments' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >these fragments i have shored against my ruins 2021
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'ImmediatePast' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >immediate past becoming ancient history or on memory
                2020</router-link
              >
              <router-link
                class="dropdown-item"
                :to="{ name: 'Thresholds' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >thresholds of no-body in particular 2020
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'Paintings' }"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                >paintings 2017-ongoing
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar"
};
</script>

<style scoped>
.dropdown-item {
  font-size: 1.5rem;
}

.navbar {
  margin: 0.5rem 1rem;
}

.navbar .navbar-brand {
  color: red !important;
  font-size: 1em;
}

.navbar .navbar-brand:hover {
  color: red;
}
</style>
