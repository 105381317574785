<template>
  <div>
    <div class="row">
      <div class="col-12 text-left mt-4">
        <img
          class="img-fluid"
          :class="clazz"
          :alt="alt"
          v-lazy="src"
          v-on="$listeners"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3 text-left mt-4">
        <slot name="headline" />
      </div>
      <div class="col-12 col-lg-9 text-left mt-4">
        <slot name="description" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageComponent",
  props: {
    src: {
      type: String
    },
    alt: {
      type: String
    },
    clazz: {
      type: String
    }
  }
};
</script>

<style scoped></style>
